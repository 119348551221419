'use client';

import { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { useGlobalState } from '@/state';
import Firebase from '@/clients/firebase/firebase-react-query';

export default function IntercomManager() {
  const { state } = useGlobalState();
  const uid = state.user?.data?.uid;

  const { data: hashData } = Firebase<{ hash: string }>().backendFunction(
    'users-getIntercomHash',
    {},
    {
      enabled: Boolean(uid),
    }
  );

  useEffect(() => {
    if (!uid || !hashData?.hash) return;

    Intercom({
      app_id: 'hsvx2sa7',
      user_id: uid,
      name: state.user.data.name,
      email: state.user.data.email,
      user_hash: hashData.hash,
    });
  }, [uid, hashData?.hash, state.user.data]);

  return null;
}
