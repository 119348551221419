export function isOutsideMainlandUS(stateAbbreviation: string): boolean {
  const nonMainlandStates = [
    'AK', // Alaska
    'HI', // Hawaii
    'PR', // Puerto Rico
    'GU', // Guam
    'VI', // Virgin Islands
    'MP', // Northern Mariana Islands
    'AS', // American Samoa
    'FM', // Federated States of Micronesia
    'MH', // Marshall Islands
    'PW', // Palau
    'AA', // Armed Forces Americas
    'AE', // Armed Forces Europe
    'AP', // Armed Forces Pacific
  ];

  return nonMainlandStates.includes(stateAbbreviation);
}
